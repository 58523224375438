// THIS IS A GENERATED FILE. DO NOT EDIT THIS FILE. ENV_IS_prod DATE GENERATED: 1744176240471 
import { Config } from "./config.def";

export const config: Config = {
  appApiRoot: "https://api.olliesports.com",
  firebaseWebConfig: {
  "apiKey": "AIzaSyCa4A3BYyxnrjLeoDJ0cuLz-eEHr3GyNjg",
  "authDomain": "ollie-app-95ed8.firebaseapp.com",
  "databaseURL": "https://ollie-app-95ed8.firebaseio.com",
  "projectId": "ollie-app-95ed8",
  "storageBucket": "ollie-app-95ed8.appspot.com",
  "messagingSenderId": "379865871775",
  "appId": "1:379865871775:web:bb1ed9333fc696b40eb9aa"
},
  stripePublic: "pk_live_zTJq947Imjml9MWtfg5balH900N51Xm9tv",
  olliePipeUrl: "https://pipe.olliesports.com/pipe",
  olliePipeFallBackUrlFirestore: "https://firestore.googleapis.com/v1/projects/ollie-app-95ed8/databases/(default)/documents/backupPipe",
  universalLinkHttpBase: "https://ollsp.co"
};
