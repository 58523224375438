import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import DashboardLayout from "src/layouts";
import LoadingScreen from "src/components/LoadingScreen";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";
import AuthLayout from "./layouts/AuthLayout";
import { createPersistedSynchronousStore } from "./utils/createPersistedSynchronousStore";
import { PersistedComposeStore, PersistedComposeStoreState } from "./components/Compose/constants";
import Page from "./components/Page";
import OrgRefunds from "./views/org/OrgRefunds";

const Login = lazy(() => import("src/views/auth/LoginView"));
const ComingSoon = lazy(() => import("src/views/misc/ComingSoon"));
const PaymentInfoIframe = lazy(() => import("src/views/payment/PaymentInfoIframe"));
const Register = lazy(() => import("src/views/auth/RegisterView"));
const Dashboard = lazy(() => import("src/views/reports/DashboardView"));
const ListTeamsWithStatsView = lazy(() => import("src/views/reports/ListTeamsWithStatsView"));
const TeamStatsList = lazy(() => import("src/views/reports/TeamStats"));
const NotFound = lazy(() => import("src/views/errors/NotFoundView"));
const AccountView = lazy(() => import("src/views/account/AccountView"));
const AccountDelete = lazy(() => import("src/views/account/AccountDelete"));
const NewClubMerchantOnboarding = lazy(() => import("src/views/misc/NewClubMerchantOnboarding"));
const Events = lazy(() => import("src/views/calendar/CalendarView"));
const MyDirectory = lazy(() => import("src/views/directory/MyDirectory"));
const OrgCoupons = lazy(() => import("src/views/org/OrgCoupons"));
const TeamDetails = lazy(() => import("src/views/team/TeamDetails"));
const MyTeams = lazy(() => import("src/views/team/MyTeams"));
const PlayerDetails = lazy(() => import("src/views/player/PlayerDetails"));
const OrgTeamTags = lazy(() => import("src/views/org/OrgTeamTags"));
const OrgReports = lazy(() => import("src/views/org/OrgReports"));
const OrgReportsRegistrationSummaryByTeam = lazy(() => import("src/views/org/OrgReportsRegistrationSummaryByTeam"));
const OrgReportsMoneyOwed = lazy(() => import("src/views/org/OrgReportsMoneyOwed"));
const OrgReportsRegistrationTeamSummary = lazy(() => import("src/views/org/OrgReportsRegistrationTeamSummary"));
const OrgReportsTeamAccountSummary = lazy(() => import("src/views/org/OrgReportsTeamAccountSummary"));
const OrgReportsCouponUsage = lazy(() => import("src/views/org/OrgReportsCouponUsage"));
const OrgReportsScheduledPaymentsByMonth = lazy(() => import("src/views/org/OrgReportsScheduledPaymentsByMonth"));
const OrgSeasons = lazy(() => import("src/views/org/OrgSeasons"));
const OrgInvoices = lazy(() => import("src/views/org/OrgInvoices"));
const OrgPayments = lazy(() => import("src/views/org/OrgPayments"));
const OrgInvoiceDetails = lazy(() => import("src/views/org/OrgInvoiceDetails"));
const OrgInvoiceManual = lazy(() => import("src/views/org/OrgInvoiceManual"));
const OrgInvoiceRegistration = lazy(() => import("src/views/org/OrgInvoiceRegistration"));
const OrgSettingsPage = lazy(() => import("src/views/org/OrgSettings/OrgSettingsPage"));
const OrgTeamSummary = lazy(() => import("src/views/org/OrgTeamSummary"));
const OrgMembers = lazy(() => import("src/views/org/OrgMembers"));
const OrgMemberDetails = lazy(() => import("src/views/org/OrgMemberDetails"));
const OrgPaymentPlans = lazy(() => import("src/views/org/OrgPaymentPlans"));
const OrgRegistrationDashboard = lazy(() => import("src/views/org/OrgRegistrationDashboard"));
const OrgRegistrationPackages = lazy(() => import("src/views/orgRegistrationPackages/OrgRegistrationPackagesList"));
const OrgRegistrationQuestions = lazy(() => import("src/views/orgRegistrationQuestions"));
const EmailLayout = lazy(() => import("src/components/Compose/MailLayout"));
const EmailList = lazy(() => import("src/views/mail/MailView/MailList"));
const EmailItem = lazy(() => import("src/views/mail/MailView/MailDetails"));
const SponsorManagement = lazy(() => import("src/views/sponsors/SponsorManagement"));
const GamesView = lazy(() => import("src/views/games/GamesView"));
const GameStatsLayout = lazy(() => import("src/views/stats/StatsView/GameStatsLayout"));
const TeamStats = lazy(() => import("src/views/stats/StatsView/TeamStats"));
const ByPlayer = lazy(() => import("src/views/stats/StatsView/ByPlayer"));
const ByPosition = lazy(() => import("src/views/stats/StatsView/ByPosition"));
const HighlightsView = lazy(() => import("src/views/stats/StatsView/HighlightsView"));
const TeamPaymentAuthenticated = lazy(() => import("src/views/payment/TeamPaymentAuthenticated"));
const TeamPaymentUnauthenticated = lazy(() => import("src/views/payment/TeamPaymentUnauthenticated"));
const OpenOrgEventRegistrationPage = lazy(() => import("src/views/openOrgEvents/OpenOrgEventRegistrationPage"));
const OpenOrgEventRespondToOfferPage = lazy(() => import("src/views/openOrgEvents/OpenOrgEventRespondToOfferPage"));
const AddEditCalendarEntry = lazy(() => import("src/views/calendar/AddEditCalendarEntry"));

type RoutesType = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: RoutesType;
}[];

export function Routes(p: { routes: RoutesType }) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {p.routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => {
                const { staticContext, ...rest } = props;
                return (
                  <Guard>
                    <Layout>{route.routes ? <Routes routes={route.routes} /> : <Component {...rest} />}</Layout>
                  </Guard>
                );
              }}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

const routes: RoutesType = [
  {
    exact: true,
    path: "/",
    component: () => <Redirect to="/app/teams" />
  },
  {
    exact: true,
    path: "/404",
    component: NotFound
  },
  {
    path: "/auth",
    layout: AuthLayout,
    routes: [
      {
        exact: true,
        guard: GuestGuard,
        path: "/auth/login",
        component: Login
      },
      {
        exact: true,
        guard: GuestGuard,
        path: "/auth/register",
        component: Register
      }
    ]
  },
  {
    path: "/payment-info-iframe",
    exact: true,
    component: PaymentInfoIframe
  },
  {
    path: "/payment-processing-setup",
    exact: true,
    component: NewClubMerchantOnboarding
  },
  {
    exact: true,
    path: "/org/:orgId/payInvoice/:orgInvoiceId",
    component: OrgInvoiceManual
  },
  {
    exact: true,
    path: "/org/:orgId/register/:playerBundleId/:orgSeasonId",
    component: OrgInvoiceRegistration
  },
  {
    exact: true,
    path: "/account/delete",
    component: AccountDelete
  },
  {
    path: "/app",
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: "/app/account",
        component: AccountView
      },
      {
        exact: true,
        path: "/app/stats/dashboard",
        component: Dashboard
      },
      {
        exact: true,
        path: "/app/stats/listTeamsWithStats",
        component: ListTeamsWithStatsView
      },
      {
        exact: true,
        path: "/app/team/:teamIdWithSquad/stats",
        component: TeamStatsList
      },
      {
        exact: true,
        path: "/app/stats/games",
        component: GamesView
      },
      {
        exact: true,
        path: "/app/stats",
        component: () => <Redirect to="/app/stats/dashboard" />
      },
      {
        exact: true,
        path: "/app/directory",
        component: MyDirectory
      },
      {
        exact: true,
        path: "/app/events",
        component: Events
      },
      {
        exact: true,
        path: "/app/events/addEdit/:teamId/:calendaryEntryType",
        component: AddEditCalendarEntry
      },
      {
        exact: true,
        path: "/app/chat",
        component: ComingSoon
      },
      {
        path: "/app/email",
        layout: (() => {
          const store = createPersistedSynchronousStore<PersistedComposeStoreState>({
            initialState: {
              selectedRecipientGroups: [],
              subject: "",
              body: "",
              attachments: []
            },
            namespace: "email-page",
            shouldBeClearedOnUpdates: true
          });

          return (p: any) => (
            <EmailLayout instanceId="email-page" fetchContextData={true} store={store}>
              <Page style={{ flexGrow: 1 }}>{p.children}</Page>
            </EmailLayout>
          );
        })(),
        routes: [
          {
            exact: true,
            path: "/app/email/:mailId",
            component: EmailItem
          },
          {
            exact: true,
            path: "/app/email",
            component: EmailList
          }
        ]
      },
      {
        exact: true,
        path: "/app/email",
        component: () => <Redirect to="/app/email/all" />
      },
      {
        exact: true,
        path: "/app/teamBilling",
        component: TeamPaymentAuthenticated
      },
      {
        exact: true,
        path: "/app/teams/:teamId",
        component: TeamDetails
      },
      {
        exact: true,
        path: "/app/org/:orgId/coupons",
        component: OrgCoupons
      },
      {
        exact: true,
        path: "/app/org/:orgId/teamTags",
        component: OrgTeamTags
      },
      {
        exact: true,
        path: "/app/org/:orgId/reports",
        component: OrgReports
      },
      {
        exact: true,
        path: "/app/org/:orgId/reports/couponUsage",
        component: OrgReportsCouponUsage
      },
      {
        exact: true,
        path: "/app/org/:orgId/reports/registrationSummaryByTeam",
        component: OrgReportsRegistrationSummaryByTeam
      },
      {
        exact: true,
        path: "/app/org/:orgId/reports/scheduledPaymentsByMonth",
        component: OrgReportsScheduledPaymentsByMonth
      },
      {
        exact: true,
        path: "/app/org/:orgId/reports/moneyOwed",
        component: OrgReportsMoneyOwed
      },
      {
        exact: true,
        path: "/app/org/:orgId/reports/teamAccountSummary",
        component: OrgReportsTeamAccountSummary
      },
      {
        exact: true,
        path: "/app/org/:orgId/reports/registrationSummaryByTeam/:teamId",
        component: OrgReportsRegistrationTeamSummary
      },
      {
        exact: true,
        path: "/app/org/:orgId/reports/:reportSlug",
        component: TeamDetails
      },
      {
        exact: true,
        path: "/app/org/:orgId/teams",
        component: OrgTeamSummary
      },
      {
        exact: true,
        path: "/app/org/:orgId/members",
        component: OrgMembers
      },
      {
        exact: true,
        path: "/app/org/:orgId/teams/:teamId",
        component: TeamDetails
      },
      {
        exact: true,
        path: "/app/org/:orgId/teams/:teamId/players/:playerId",
        component: PlayerDetails
      },
      {
        exact: true,
        path: "/app/org/:orgId/members/:accountIdOrPlayerBundleId",
        component: OrgMemberDetails
      },
      {
        path: "/app/org/:orgId/members/:accountIdOrPlayerBundleId/payment-info-iframe",
        exact: true,
        component: PaymentInfoIframe
      },
      {
        exact: true,
        path: "/app/org/:orgId/teams/:teamId/:route/members/:accountIdOrPlayerBundleId",
        component: OrgMemberDetails
      },
      {
        exact: true,
        path: "/app/org/:orgId/registrations/members/:accountIdOrPlayerBundleId",
        component: OrgMemberDetails
      },
      {
        exact: true,
        path: "/app/org/:orgId/registrations",
        component: OrgRegistrationDashboard
      },
      {
        exact: true,
        path: "/app/org/:orgId/registrationPackages",
        component: OrgRegistrationPackages
      },
      {
        exact: true,
        path: "/app/org/:orgId/registrationQuestions",
        component: OrgRegistrationQuestions
      },

      {
        exact: true,
        path: "/app/org/:orgId/paymentPlans",
        component: OrgPaymentPlans
      },
      {
        exact: true,
        path: "/app/org/:orgId/seasons",
        component: OrgSeasons
      },
      {
        exact: true,
        path: "/app/org/:orgId/invoices",
        component: OrgInvoices
      },
      {
        exact: true,
        path: "/app/org/:orgId/payments",
        component: OrgPayments
      },
      {
        exact: true,
        path: "/app/org/:orgId/refunds",
        component: OrgRefunds
      },
      {
        exact: true,
        path: "/app/org/:orgId/invoices/:orgInvoiceId/:playerId",
        component: OrgInvoiceDetails
      },
      {
        exact: true,
        path: "/app/org/:orgId/invoices/:orgInvoiceId",
        component: OrgInvoiceDetails
      },
      {
        exact: true,
        path: "/app/org/:orgId/registrations/:orgInvoiceId/:playerId",
        component: OrgInvoiceDetails
      },
      {
        exact: true,
        path: "/app/org/:orgId/registrations/:orgInvoiceId",
        component: OrgInvoiceDetails
      },
      {
        exact: true,
        path: "/app/invoices/:orgInvoiceId",
        component: OrgInvoiceDetails
      },

      {
        exact: true,
        path: "/app/org/:orgId/settings",
        component: OrgSettingsPage
      },
      {
        exact: true,
        path: "/app/teams",
        component: MyTeams
      },
      {
        exact: true,
        path: "/app/teams/:teamId/:route",
        component: TeamDetails
      },
      {
        exact: true,
        path: "/app/team/:teamId/players/:playerId",
        component: PlayerDetails
      },
      {
        exact: true,
        path: "/",
        component: () => <Redirect to="/app/teams" />
      },
      {
        exact: true,
        path: "/app",
        component: () => <Redirect to="/app/teams" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: "/sponsor/edit/:sponsorId/:editToken",
    exact: true,
    component: SponsorManagement
  },
  {
    path: "/stats/:calendarEntryId",
    layout: GameStatsLayout,
    routes: [
      {
        exact: true,
        path: "/stats/:calendarEntryId",
        component: HighlightsView
      },
      {
        exact: true,
        path: "/stats/:calendarEntryId/byPlayer",
        component: ByPlayer
      },
      {
        exact: true,
        path: "/stats/:calendarEntryId/ByPosition",
        component: ByPosition
      },
      {
        exact: true,
        path: "/stats/:calendarEntryId/teamStats",
        component: TeamStats
      }
    ]
  },
  {
    path: "/payment/team/:teamId/:couponCode",
    exact: true,
    component: TeamPaymentUnauthenticated
  },
  {
    path: "/payment/team/:teamId",
    exact: true,
    component: TeamPaymentUnauthenticated
  },
  {
    path: "/tryouts/:orgSlug/:eventSlug",
    exact: true,
    component: OpenOrgEventRegistrationPage
  },
  {
    path: "/camps/:orgSlug/:eventSlug",
    exact: true,
    component: OpenOrgEventRegistrationPage
  },
  {
    path: "/tryouts/respond/:teamId/:openOrgEventRegistrationId",
    exact: true,
    component: OpenOrgEventRespondToOfferPage
  },
  {
    path: "/open-events/:orgSlug/:eventSlug",
    exact: true,
    component: OpenOrgEventRegistrationPage
  },
  {
    component: () => <Redirect to="/404" />
  }
];

export default routes;
